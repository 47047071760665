@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}


body {
  margin: 0;
  background:  #FFFFFF;
  font-family: 'Poppins', sans-serif;
  
}
.error{
  color: red;
}
.navbar {
  
  font-family: "Lobster", sans-serif;
}
.button-4 {
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.button-4:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}


.header {
  position: relative; 
  width: 100%;
  margin: auto;
  padding: 20px;
  background: #31004a;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
}
.logo {
  font-size: 20px;
  color: white;
  text-decoration: none;
  padding-left: 5px;
  padding-right: 5px;

}

.questions {
  position: relative; 
  width: 100%;
  margin: auto;
  padding: 20px;
  
  display: flex;
  justify-content: center;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
}
.phone{
  margin-right: 5px;
}
.topImage{
  display: flex;
  justify-content: center;
  padding-top: 25px;
}



.estimate4{
  
  display: flex;
  align-items: center;
  padding: 10px;
  background: #FFFFFF;
}
.estimate2{
  
  display: flex;
  align-items: center;
  padding: 25px;
  background: #31004a;
}
.estimate5{
  
  display: flex;
  align-items: center;
  padding: 25px;
  background: #31004a;
}
.form-padding{
  padding: 25px;
  max-width: 1200px;
  margin: 0 auto; /* Center the element */


}
.container{

  background: white;
  min-height: 400px;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0,0,0,0.5)
}

.container2{
  position: relative;
  background: white;
  
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
  height: 384px;

}
.container3{
  position: relative;
  background: white;
  
  padding: 25px;
  border-radius: 10px;
  min-height: 384px;
  box-shadow: 0 0 15px rgba(0,0,0,0.5)
}

.container header{
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.container2 header{
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  min-width: 180px;
}

.container3 header{
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.container2 p {
  display: block; /* Make each <a> element a block element */
  margin-bottom: 10px; /* Add some bottom margin between each item */
}

.form .input-box{
  width: 100%;
  margin-top: 20px
}
.form .input-box input{
  position: relative;
  height: 50px;
  width: 100%;
  outline: none;
  border: 1px solid black;
  border-radius: 8px;
  padding: 0 15px;

}

.form .input-box textarea{
  position: relative;
  height: 150px;
  width: 100%;
  outline: none;
  border: 1px solid black;
  border-radius: 8px;
  padding: 15px 15px;
  resize: none;

}

img{
  border-radius: 10px;
  position: relative;
  max-height: 600px;
  z-index: 100;


}


.asdf2{
  width: 100%;
}



.custom-shape-divider-top-1705389671 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);


}

.custom-shape-divider-top-1705389671 svg {
  position: relative;
  display: block;
  width: calc(130% + 1.3px);
  height: 254px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));


}

.custom-shape-divider-top-1705389671 .shape-fill {
  fill: #FFFFFF;
}

.spacer {
  aspect-ratio: 960/400;
  width: 100%;
  background-repeat: no-repeat;
  background-position: cover;
  background-size: cover;
}

.layer1 {
  background-image: url("./layered-waves-haikei (1).svg")
}

@media (max-width: 900px) {
  .container header{
    font-size: 1rem;
  }
  .topImage{
    transform: scale(0.7);
  }


}
@media (max-width: 600px) {

  .topImage{
    transform: scale(0.5);
  }



}

@media (max-width: 520px){

  /* .asdf{
    display: none;
  }
  .asdf2{
    display: initial;
    margin: auto;
  }*/
  .estimate2 .container2{
    display: none;

  } 
  .container3{
    width: 115%;
  }

  .estimate5 .container2{
    display: initial;
    margin:auto;
    
  }
  





}
@media (min-width: 520px){

  .estimate2 .container2{
    display: initial;

  }
  .estimate5 .container2{
    display: none;

  }

}
@media (max-width: 420px){
  .topImage{
    transform: scale(0.4);
    padding-top: 0px;


  }
  .form-padding{
    padding-top: 0px;
    max-width: 1200px;
    margin: 0 auto; /* Center the element */
  
  
  }
  


}